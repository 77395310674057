import { clsx } from 'clsx';
import * as React from 'react';

import * as style from './InfrastructureLayout.css';

interface IInfrastructureLayoutProps {
  isMobile?: boolean;
  children: React.ReactNode;
}

export const InfrastructureLayout: React.FC<IInfrastructureLayoutProps> = ({ children, isMobile = false }) => {
  return (
    <div className={style['container']}>
      <div
        className={clsx(style['wrapper'], {
          [style['wrapper-mobile']]: isMobile,
        })}
      >
        {children}
      </div>
    </div>
  );
};
