import { UIHeading5, IconRoute16 } from '@cian/ui-kit';
import clsx from 'clsx';
import * as React from 'react';

import * as style from './style.css';

interface IRouteButtonProps {
  isMobile?: boolean;
  fixed: boolean;
  onClick: () => void;
}

export const RouteButton: React.FC<IRouteButtonProps> = ({ fixed, onClick, isMobile = false }) => {
  return (
    <div
      className={clsx(style['wrapper'], fixed ? style['fixed'] : style['normal'], {
        [style['wrapper-mobile']]: isMobile,
      })}
    >
      <button
        className={clsx(style['button'], {
          [style['button-mobile']]: isMobile,
        })}
        onClick={onClick}
      >
        <IconRoute16 color={isMobile ? 'icon-primary-default' : 'icon-main-default'} />
        {!isMobile && <UIHeading5 color="text-primary-default">Построить маршрут</UIHeading5>}
      </button>
    </div>
  );
};
