import * as React from 'react';
import { useSelector } from 'react-redux';

import {
  ALWAYS_VISIBLE_INFRASTRUCTURE_TYPES,
  INFRASTRUCTURE_ICON_MAP,
} from 'shared/mf-infrastructure/constants/infrastructure';
import {
  selectSimilarNewbuildingIds,
  selectSimilarNewbuildingsModelVersion,
} from 'shared/mf-infrastructure/selectors/similarNewbuildings';
import { selectSimilarObjectIds } from 'shared/mf-infrastructure/selectors/similarObjects';
import { TFutureRoad } from 'shared/mf-infrastructure/types/futureInfrastructure';
import {
  IInfrastructureType,
  ISetInfrastrutureTypesPayload,
  TRegularInfrastructureType,
} from 'shared/mf-infrastructure/types/infrastructure';

import { InfrastructureTypeItem, InfrastructureTypesLayout } from '../../../components/Infrastructure';
import { IAnalyticsParams } from '../hooks';
import { trackInfrastructureTypeClick, trackSimilarNewbuildingInfrastructureTypeClick } from '../tracking';

interface IInfrastructureMapTypesProps {
  analyticsParams: IAnalyticsParams;
  fixed: boolean;
  currentTypes: string[];
  onTypesChange: (payload: ISetInfrastrutureTypesPayload) => void;
  types: IInfrastructureType[] | null;
  futureTypes?: (TRegularInfrastructureType | TFutureRoad)[];
}

export const InfrastructureMapTypes: React.FC<IInfrastructureMapTypesProps> = ({
  analyticsParams,
  fixed,
  types,
  futureTypes = [],
  currentTypes,
  onTypesChange,
}) => {
  const similarObjectIds = useSelector(selectSimilarObjectIds);
  const similarNewbuildingIds = useSelector(selectSimilarNewbuildingIds);
  const simlareNewbuildingsModelVersion = useSelector(selectSimilarNewbuildingsModelVersion);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.currentTarget.checked;
      const checkedType = event.currentTarget.value;
      const newActiveTypes = Array.from(ALWAYS_VISIBLE_INFRASTRUCTURE_TYPES);
      let newCheckedType = null;

      if (isChecked) {
        newCheckedType = checkedType;
        newActiveTypes.push(checkedType);

        if (checkedType === 'similar_newbuildings') {
          trackSimilarNewbuildingInfrastructureTypeClick({
            blockType: analyticsParams.blockType,
            modelVersion: simlareNewbuildingsModelVersion,
            newbuildingIds: similarNewbuildingIds,
          });
        } else {
          trackInfrastructureTypeClick({
            profile: analyticsParams.profile,
            infrastructureType: checkedType,
            blockType: analyticsParams.blockType,
            isFutureServiceOpened: analyticsParams.isFutureServiceOpened,
            similarObjectIds,
          });
        }
      }

      onTypesChange({
        activeTypes: newActiveTypes,
        checkedType: newCheckedType,
      });
    },
    [analyticsParams, onTypesChange, simlareNewbuildingsModelVersion, similarNewbuildingIds, similarObjectIds],
  );

  if (!types) {
    return null;
  }

  return (
    <InfrastructureTypesLayout fixed={fixed}>
      {types.map(({ type, title }, index) => {
        const checked = currentTypes.includes(type);
        const hasNewData = futureTypes.includes(type);
        const IconComponent = INFRASTRUCTURE_ICON_MAP.get(type);

        if (!IconComponent) {
          return null;
        }

        return (
          <InfrastructureTypeItem
            key={type + index}
            checked={checked}
            hasNewData={hasNewData}
            type={type}
            title={title}
            icon={<IconComponent color="icon-secondary-default" />}
            handleChange={handleChange}
          />
        );
      })}
    </InfrastructureTypesLayout>
  );
};
